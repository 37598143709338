<template>
  <div class="container">
    <Header></Header>
    <div class="details-box">
      <div class="title">
        <div class="caption">
          《智能制造成立成熟度模型》----智能制造的“体检表”
        </div>
      </div>
      <div class="content">
        <div class="category">归类：体系搭建</div>
        <p>
           我们致力于为您提供更优质的培训课程，助力您提升管理技能、拓展业务领域。我们的课程涵盖了数字化转型、管理体系建设、业务流程优化、
           质量零缺陷管理等多个方面，为你提供全方位的培训支持。通过我们的专业指导和实践操作，您将掌握先进的数字化转型策略和技巧，轻松应对市场挑战，
           实现销售业绩的持续增长。无论您是初学者还是资深管理人员，我们都有适合您的课程，助您取得更大的成功！
        </p>
        <div class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/mobile/Header.vue';
export default {
    components:{
        Header
    },
}
</script>

<style lang="less" scoped>
.container{
    min-height: 100vh;
    background-color: #ffffff;
    .details-box{
        padding: 0 .3rem;
        box-sizing: border-box;
        .title{
          width: 100%;
          text-align: center;
          padding: .58rem 0 .48rem;
          border-bottom: 1px solid #c9c9c9;
          .caption{
            width: 4.57rem;
            height: .64rem;
            font-size: 0.32rem;
            font-weight: bold;
            color: #000000;
            line-height: 0.32rem;
            margin: 0 auto;
          }
        }
        .content{
          margin-top: .32rem;
          .category{
            height: .26rem;
            font-size: .26rem;
            font-weight: 400;
            color: rgba(81, 81, 81, 0.7);
            line-height: .26rem;
          }
          p{
            text-indent: 2em;
            font-size: 0.28rem;
            font-weight: 500;
            color: #555555;
            line-height: 0.48rem;
            margin-top: .28rem;
          }
          .img{
            margin-top: .35rem;
            width: 100%;
            height: 4.64rem;
            background: url("../../assets/mobileImgs/bg4.png") no-repeat;
            background-size: 100% 100%;
          }
        }
    }
}
</style>